export enum SignStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
}

export const signStatusDescriptions: Record<SignStatus, string> = {
  [SignStatus.SIGNED]: 'Gezeichnet',
  [SignStatus.UNSIGNED]: 'Nicht gezeichnet',
  [SignStatus.PENDING_CONFIRMATION]: 'Wartet auf Rückmeldung',
};
