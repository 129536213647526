<!-- TODO Check if article image can be used after models are confirmed -->
@if (conditionArticle().articleImage; as articleImage) {
  <img [src]="articleImage" />
}

<div class="article-info">
  <div class="article-info__description truncate">
    <mp-article-type-icon [type]="conditionArticle().articleType" [small]="true" />
    <span class="truncate" [title]="conditionArticle().articleName">{{ conditionArticle().articleName }}</span>
  </div>

  <div class="article-info__category truncate">
    <mat-icon class="article-info__category-icon material-icons-outlined">category</mat-icon>

    <span class="article-info__category-type truncate" [title]="conditionArticle().category.categoryType">
      {{ conditionArticle().category.categoryType }}:</span
    >

    <span class="article-info__category-description truncate" [title]="conditionArticle().category.categoryDescription">
      {{ conditionArticle().category.categoryDescription }}
    </span>

    <div class="article-info__category-code truncate" [title]="conditionArticle().category.categoryCode">
      {{ conditionArticle().category.categoryCode }}
    </div>
  </div>
</div>
