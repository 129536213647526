import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { ContractStatusType } from '@mp/contract-management/common/domain';

@Component({
  selector: 'mpctm-contract-list-item-card',
  standalone: true,
  templateUrl: './contract-list-item-card.component.html',
  styleUrls: ['./contract-list-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule],
})
export class ContractListItemCardComponent {
  @Input({ required: true }) statusType!: ContractStatusType;

  readonly cardCssClass = 'contract-list-item-card';

  getListItemClass(): string {
    const modifier: string = this.getListItemModifierClass();
    return `${this.cardCssClass} ${this.cardCssClass}--${modifier}`;
  }

  private getListItemModifierClass(): string {
    switch (this.statusType) {
      case ContractStatusType.Approved:
        return 'approved';
      case ContractStatusType.Archived:
        return 'archived';
      case ContractStatusType.Modified:
        return 'modified';
      case ContractStatusType.Created:
        return 'created';

      default:
        return '';
    }
  }
}
