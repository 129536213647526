import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpctm-condition-validity-period',
  standalone: true,
  templateUrl: './condition-validity-period.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe],
})
export class ConditionValidityPeriodComponent {
  readonly validFrom: InputSignal<string | null> = input.required<string | null>();
  readonly validUntil: InputSignal<string | null> = input.required<string | null>();
}
